@import "./assets/fonts/fontawesome/css/all.min.css";
@import "@angular/material/theming";
@import "../node_modules/leaflet/dist/leaflet.css";

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat-typography-config(
  $font-family: "Arial",
  $headline: mat-typography-level(32px, 48px, 700),
  $body-1: mat-typography-level(16px, 24px, 500),
);

* {
  font-family: "Arial", sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

*:focus {
  outline: none;
}

body {
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
  margin: 0px;
  padding: 0px;

  font-size: 16px;
  color: var(--text-default-color);
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 18px;
}

h3 {
  font-size: 16px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Arial, Geneva, Tahoma, sans-serif;
}

/*PROGRESSBAR*/

.mat-progress-bar-fill::after {
  background-color: var(--bg-primary-color);
}


.mat-progress-bar-buffer {
  background: var(--bg-tertiary-color);
}

/*BUTTON MENU*/
.mat-menu-panel {
  border-radius: 0px !important;

  .mat-menu-content {
    .mat-menu-item {
      display: flex;
      align-items: center;

      i {
        font-size: 18px;
        margin-right: 5px;
      }
    }
  }
}

/*BUTTON*/

button {
  font-size: 14px !important;
  border-radius: 0px !important;
  text-transform: uppercase;
  opacity: 0.9;
  min-width: auto !important;
  transition: all 0.5s ease;

  &:hover {
    opacity: 1;
  }

  &.primary {
    background-color: var(--bg-secondary-color);
    color: var(--text-secondary-color);
  }

  &.secondary {
    background-color: var(--bg-primary-color);
    color: var(--text-primary-color);
  }

  &.tertiary {
    background-color: var(--bg-tertiary-color);
    color: var(--text-tertiary-color);
  }

  &.close {
    background-color: var(--bg-red-color);
    color: var(--text-red-color);
  }

  &.save {
    background-color: var(--bg-primary-color);
    color: var(--text-primary-color);
  }

  &.exportar {
    background-color: var(--bg-secondary-color);
    color: var(--text-secondary-color);
    i {
      font-size: 19px;
      margin: 0px 5px 0px -5px;
      padding: 0px;
      line-height: 16px;
    }
  }

  &.mat-button[disabled][disabled] {
    background-color: var(--bg-tertiary-color);
    color: var(--text-tertiary-color);
  }
}

/*NAVBAR*/
.sidenav-container {
  .sidenav {
    width: 300px;
  }

  .mat-toolbar {
    padding: 0px;
    z-index: 10;
    position: fixed;
    transition: all 0.5s ease;
    background: var(--bg-primary-color);
    color: var(--text-primary-color);
    height: 50px;

    display: flex;
    justify-content: space-between;

    button {
      border: 0px;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .bloco-1 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 30%;
      height: 100%;

      .fechar-abas {
        position: relative;

        .close {
          position: absolute;
          font-size: 12px;
          margin-top: 2px;
        }
      }

      .formMenuAutoComplete {
        margin: 0px;
        padding: 0px;
        height: 100%;
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;

        .mat-form-field {
          width: 100%;

          input {
            padding: 0px;
          }
        }
      }
    }

    .bloco-2 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40%;
      height: 100%;

      img {
        height: 80%;
        width: auto;
      }
    }

    .bloco-3 {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 30%;
      height: 100%;

      .imageUser {
        width: 30px;
        height: 30px;
        border-radius: 50px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: 1px solid #fff;
      }
    }
  }

  .mat-tab-group {
    margin-top: 50px !important;
    height: calc(100vh - 50px) !important;
    position: relative;
    background-color: #ffff;
  }

  .mat-tab-header {
    z-index: 9;
    width: 100vw;
    position: fixed !important;
    background-color: var(--bg-secondary-color);
    border: 0px;
    
    .mat-tab-header-pagination-chevron {
      border-color: var(--text-secondary-color);
    }
  }

  .mat-tab-body {
    background-color: #fff !important;
  }
  
  .mat-tab-body-wrapper {
    position: relative !important;
    margin-top: 48px;
    height: 100%;
  }
  
  
  
  .mat-tab-list {
    .mat-tab-label {
      padding: 0 10px 0 30px;
      min-width: auto;
      color: var(--text-primary-color);
      opacity: 0.5;
      text-transform: uppercase;
      
      &.mat-tab-label-active {
        background-color: #000;
        color: var(--text-primary-color);
        opacity: 1;
      }
      
      .mat-tab-label-content {
        position: relative;
        width: 100%;

        .material-icons {
          position: absolute;
          left: -25px;
          font-size: 16px;
          padding: 1px;
          transition: all 0.5s ease;
          border-radius: 10px;

          &:hover {
            background-color: var(--bg-tertiary-color);
            color: var(--text-tertiary-color);
          }
        }
      }
    }

    .mat-ink-bar {
      background-color: var(--bg-primary-color);
    }
  }

  .subTabs {
    .mat-tab-group {
      height: calc(100vh - 98px) !important;
      margin-top: 0px !important;
      .mat-tab-header {
        background-color: var(--bg-subtab);
        
        
        .mat-tab-label {
          color: var(--text-subtab);
          padding: 0 15px;
          
          &.mat-tab-label-active {
            background-color: var(--bg-subtab-active);
            color: var(--text-subtab-active);
          }
        }
      }
    }
  }
}

.menu-usuario {
  .mat-menu-content {
    padding: 0px !important;

    h2 {
      padding: 0px 15px !important;
      font-size: 16px !important;
    }
  }
}

//*AJUSTE AUTOCOMPLETE
.cdk-overlay-pane {
  margin-top: -1px;
}



.mat-autocomplete-panel {
  .categoria {
    border-top: 1px solid var(--bg-secondary-color);
    position: relative;

    small {
      z-index: 1;
      margin: 0px;
      padding: 4px;
      line-height: 10px;
      font-size: 10px;
      position: absolute;
      top: 0px;
      right: 0px;
      background-color: var(--bg-secondary-color);
      color: var(--text-secondary-color);
    }

    .mat-option {
      &.mat-active,
      &:hover {
        background-color: var(--bg-hover-list);
      }
    }
    
    .mat-option-text {
      a {
        display: block;
      }

      .itens {
        flex-direction: column;
        display: flex;
      }
    }
  }
}

/*DIALOG*/
.mat-mdc-dialog-container {
  padding: 0px !important;
  border-radius: 0px !important;

  .mat-dialog-title {
    background-color: var(--bg-secondary-color);
    color: var(--text-secondary-color);

    display: flex;
    justify-content: space-between;
    height: 35px;
    margin: 0px;

    h2 {
      padding: 0px 0px 0px 7px;
      font-size: 16px;
      margin: 0px;
    }

    button {
      height: 35px;
      width: 35px;
      padding: 0px;
      margin: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: auto;
      transition: all 0.5s ease;
    }
  }

  .mat-dialog-content {
    margin: 0px;
    padding: 15px;
    display: block;
  }

  .mat-dialog-actions {
    background-color: var(--bg-quaternary-color);
    border-top: 1px solid var(--bg-tertiary-color);
    color: var(--text-quaternary-color);

    display: flex;
    justify-content: space-between;
    height: 49px;
    min-height: auto;
    margin: 0px;
    padding: 0px;

    div {
      margin: 0px 7px;

      button {
        padding: 0px 15px 0px 10px !important;
        min-width: auto;

        i {
          font-size: 20px !important;
        }
      }
    }
  }
}

/*FORM */
form {
  padding: 0px;
  position: relative;

  .icone-show-hide-password {
    position: absolute;
    right: 10px;
    top: 13px;
    z-index: 100;
    cursor: pointer;
  }

  .mat-progress-bar {
    position: absolute;
    margin-top: -2px;
  }

  &.default {
    ul {
      list-style: none;
      margin: 0px;
      padding: 0px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      li {
        margin-right: 10px;
        height: 60px;
        margin-bottom: 20px;

        &:last-child {
          margin-right: 10px;
        }
      }
    }
  }

  ws-combo {
    width: 100%;
  }

  .mat-card {
    .mat-card-header {
      
      .mat-card-header-text {
        margin: 0px !important;
      }
    }
  }

  .mat-grid-tile {
    .mat-figure {
      .mat-slide-toggle {
        margin-top: -18px;
      }
    }
  }
}

/*AUTO COMPLETE SEARCH*/
.mat-primary {
  .mat-option {
    &.mat-selected {
      
      &:not(.mat-option-disabled) {
        color: var(--text-tertiary-color);
      }
    }
  }

  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate {
    background-color: var(--bg-primary-color);
  }
}


.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background {
  background: var(--bg-primary-color) !important;
}

/*LOADING*/
.mat-progress-spinner circle,
.mat-progress-spinner circle {
  stroke: var(--bg-primary-color) !important;
}

.page-content {
  height: 100%;
  display: grid;

  &.page-content-row-2 {
    grid-template-rows: 60px calc(100% - 68px);
    grid-row-gap: 8px;
  }
}

.box-filter {
  padding: 7px;
  border-bottom: 1px solid #f3f3f3;
  height: 50px;
  display: flex;

  form {
    display: grid;
    grid-template-columns: 280px 1fr 160px;
    grid-template-rows: 50px;
    column-gap: 15px;

    .buttons-container {
      display: flex;
      align-items: center;

      button[type="submit"],
      button[type="button"] {
        height: 42px;
        width: 100%;
      }
    }
  }

  ws-combo {
    .mat-form-field-error {
      display: none;
    }
  }
}

.mat-slider {
  
  .mat-slider-thumb,
  .mat-slider-thumb-label,
  .mat-slider-track-fill {
    background-color: red !important;
  }
}

@media only screen and (max-width: 600px) {
  .sidenav-container {
    .mat-toolbar {
      .bloco-1 {
        width: 70% !important;
      }
      .bloco-2 {
        display: none !important;
      }
    }
  }
  button {
    width: 40px;
    height: 40px;
    padding: 0px;

    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      display: none;
    }
  }
}
