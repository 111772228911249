:root {
  --bg-modal-tab-active: #707070;
  --text-modal-tab-active: #ffffff;

  --bg-subtab: #cacaca;
  --text-subtab: #343434;
  --bg-subtab-active: #707070;
  --text-subtab-active: #ffffff;

  --bg-red-color: #ff0000;
  --text-red-color: #ffffff;

  --bg-black-color: #000000;
  --bg-black-color-alpha: #0000008c;

  --bg-hover-list: #dbdbdb;

  .green {
    --bg-primary-color: #07da00;
    --bg-secondary-color: #343434;
    --bg-tertiary-color: #cacaca;
    --bg-quaternary-color: #f3f3f3;

    --text-primary-color: #ffff;
    --text-secondary-color: #ffff;
    --text-tertiary-color: #343434;
    --text-quaternary-color: #343434;
    --text-default-color: #333333;
  }

  .orange {
    --bg-primary-color: #ffb400;
    --bg-secondary-color: #343434;
    --bg-tertiary-color: #cacaca;
    --bg-quaternary-color: #f3f3f3;

    --text-primary-color: #ffff;
    --text-secondary-color: #ffff;
    --text-tertiary-color: #343434;
    --text-quaternary-color: #343434;
    --text-default-color: #333333;
  }

  .red {
    --bg-primary-color: #ff0000;

    --bg-secondary-color: #343434;
    --bg-tertiary-color: #cacaca;
    --bg-quaternary-color: #f3f3f3;

    --text-primary-color: #ffff;
    --text-secondary-color: #ffff;
    --text-tertiary-color: #343434;
    --text-quaternary-color: #343434;
    --text-default-color: #333333;
  }

  .black {
    --bg-primary-color: #000000;
    --bg-secondary-color: #343434;
    --bg-tertiary-color: #cacaca;
    --bg-quaternary-color: #f3f3f3;

    --text-primary-color: #ffff;
    --text-secondary-color: #ffff;
    --text-tertiary-color: #343434;
    --text-quaternary-color: #343434;
    --text-default-color: #333333;
  }

  .blue {
    --bg-primary-color: #248aff;
    --bg-secondary-color: #343434;
    --bg-tertiary-color: #cacaca;
    --bg-quaternary-color: #f3f3f3;

    --text-primary-color: #ffff;
    --text-secondary-color: #ffff;
    --text-tertiary-color: #343434;
    --text-quaternary-color: #343434;
    --text-default-color: #333333;
  }
}
