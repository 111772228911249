@use '@angular/material' as mat;
@import '@angular/material/theming';

@include mat.core();
@include mat.legacy-core();

@include mat.all-legacy-component-typographies();
@include mat.all-component-typographies();

// Define the datepicker theme.
$datepicker-primary: mat-palette($mat-red, 600, 900, 400);
$datepicker-accent: mat-palette($mat-red, A200, A100, A400);
$datepicker-theme: mat-light-theme(
  (
    color: (
      primary: $datepicker-primary,
      accent: $datepicker-accent,
    ),
  )
);

// Apply the theme to your component
@include mat.all-component-themes($datepicker-theme);
@include mat.all-legacy-component-themes($datepicker-theme);
@include mat.datepicker-theme($datepicker-theme);

// Define the checkbox theme.
$checkbox-theme: mat-light-theme(
  var(--bg-primary-color),
  var(--bg-tertiary-color)
);

// Apply the theme to your component
@include mat.checkbox-theme($datepicker-theme);

/* BUTTON */
button {
  cursor: pointer;
}

/*INPUT*/
//REMOVER SPINER CAMPO TYPE NUMBER
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}


.mat-form-field-disabled .mat-form-field-underline {
  height: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  /* Firefox */
}


.mat-form-field-appearance-legacy .mat-form-field-label {
  color: var(--text-default-color) !important;
}


.mat-form-field-appearance-outline .mat-form-field-outline {
  color: var(--text-default-color) !important;
}

.mat-form-field-appearance-outline {
  
  .mat-form-field-infix {
    padding: 0.7em 0 0.3em 0 !important;
    top: -0.3em;
  }
  
  .mat-select-arrow-wrapper {
    transform: translateY(0.5px);
  }
  
  
  .mat-form-field-outline {
    color: var(--bg-tertiary-color) !important;
    
    .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
      border-radius: 0px !important;
    }
  }
}


.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0 0em !important;
}


.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label {
  margin-top: -2px !important;
}

.mat-form-field {
  width: 100%;
  
  .mat-form-field-label {
    margin-top: -0.4em !important;
  }
  
  .mat-form-field-wrapper {
    margin-right: 1px !important;
  }

  .invalid-feedback {
    position: absolute;
    margin-top: 15px;
    font-size: 12px;
    color: var(--bg-red-color);
  }

  &.mat-focused {
    
    .mat-form-field-label {
      color: #000 !important;
    }
    
    .mat-form-field-outline {
      color: #000 !important;
    }
  }
}

.mat-checkbox {
  display: flex;
  align-items: center;
  height: 100%;
  
  .mat-checkbox-checked.mat-accent .mat-checkbox-background,
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: var(--bg-primary-color) !important;
  }
  
  &:not(.mat-checkbox-disabled) {
    &.mat-accent {
      .mat-ripple-element {
        background: var(--bg-primary-color);
      }
    }
  }
}


.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: var(--bg-secondary-color);
}


.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: var(--bg-secondary-color);
}


.mat-form-field-appearance-outline
  .mat-form-field-outline-thick
  .mat-form-field-outline-end,
.mat-form-field-appearance-outline
  .mat-form-field-outline-thick
  .mat-form-field-outline-gap,
.mat-form-field-appearance-outline
  .mat-form-field-outline-thick
  .mat-form-field-outline-start {
  border-width: 1px !important;
}


.mat-form-field-appearance-outline.mat-form-field-disabled
  .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

/*DISABLED*/

.mat-select-disabled .mat-select-value {
  color: var(--text-default-color) !important;
}
.mat-input-element:disabled {
  color: var(--text-default-color) !important;
}


.mat-slide-toggle .mat-slide-toggle-bar {
  background-color: var(--bg-tertiary-color);
}


.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: var(--bg-tertiary-color);
}


.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: var(--bg-primary-color);
}


.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--bg-tertiary-color);
}


.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--bg-primary-color);
}


.mat-form-field-suffix {
  top: 0em !important;
}

.box-pesquisa {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid var(--bg-tertiary-color);

  .mat-icon {
    color: var(--bg-tertiary-color);
  }
}
