/*MODAL*/
.modal {
  background-color: rgba(50, 50, 50, 0.5);
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  left: 0px;
  z-index: 500;

  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    position: relative;
    background-color: #fff;
    min-width: 600px;
    max-width: 90%;

    overflow: hidden;

    -webkit-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.5);
    -moz-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.5);
    box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.5);
  }

  .modal-container {
    padding: 0px !important;
    border-radius: 0px !important;
    position: relative;

    ws-grid {
      height: 100%;
      .mat-elevation-z8 {
        height: 350px !important;
      }
    }

    .modal-title {
      background-color: var(--bg-secondary-color);
      color: var(--text-secondary-color);

      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 35px;
      margin: 0px;

      cursor: all-scroll;

      h2 {
        padding: 0px 0px 0px 7px;
        font-size: 16px;
        margin: 0px;
      }

      button {
        height: 35px;
        width: 35px;
        padding: 0px;
        margin: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: auto;
        transition: all 0.5s ease;
      }
    }

    .modal-content {
      margin: 0px;
      padding: 15px;

      max-height: calc(100vh - 290px) !important;
      overflow: auto;

      &.gridList {
        padding: 0px;
      }
      &.gridList-modal {
        padding: 0px;
        height: 400px;
      }
    }

    .modal-actions {
      background-color: var(--bg-quaternary-color);
      border-top: 1px solid var(--bg-tertiary-color);
      color: var(--text-quaternary-color);

      display: flex;
      justify-content: space-between;
      height: 49px;
      min-height: auto;
      margin: 0px;
      padding: 0px;

      .buttons {
        margin: 0px 7px;
        display: flex;
        align-items: center;

        button {
          padding: 0px 15px 0px 10px !important;
          margin-left: 5px;

          i {
            font-size: 20px !important;
          }
        }
      }
    }

    .mat-tab-group {
      margin: 0px !important;
      height: calc(100% - 35px) !important;

      .mat-tab-header {
        width: auto;
        position: static !important;
        background-color: var(--bg-tertiary-color);
        
        .mat-tab-label {
          padding: 0 15px;
          color: var(--text-tertiary-color);
        }
        
        .mat-tab-label-active {
          background-color: var(--bg-modal-tab-active);
          color: var(--text-modal-tab-active);
        }
      }
      
      .mat-tab-body-wrapper {
        margin: 0px;
        display: block;
      }
    }
  }
  .mat-tab-body {
    //position: static !important;
  }

  .mat-toolbar-row {
    z-index: 90;
  }
}

@media only screen and (max-width: 600px) {

  .modal{
    border-top: 1px solid #fff;
    .box{
      min-width: 100% !important;
      max-width: 100% !important;
      height: 100% !important;
      .modal-container{
        height: 100% !important;
        .modal-content{
          height: calc(100% - 30px) !important;
          max-height: max-content !important;
        }
        form{
          height: calc(100% - 85px) !important;
        }
      }
      .modal-actions{
        position: fixed !important;
        width: 100% !important;
        bottom: 0px !important;

        .buttons{
          button{
            width: 40px;
            height: 40px;
            padding: 0px !important;
      
            display: flex;
            align-items: center;
            justify-content: center;

            .text{
              display: none;
            }
          }
        }
      }
    }
  }
}